// Footer.js file
import React from 'react';
import "../../../styles/graphicComponents/footer/Footer.css";

const Footer = () => {
  return (
    <footer id="permanent-footer" className="footer-container">
      <div className="footer-column">
        <p id="footer-gorom-url">Version 1.0.0 of the <strong>SEED INTRANET 2024</strong></p>
      </div>
      <div className="footer-column">
        <p id="footer-contact-info">If you have any issue, please contact <strong>fai.ahertz@seed-alumni.org</strong></p>
      </div>
      <div className="footer-column">
        {/* Placeholder for logo image */}
        <img id="footer-logo" src={`${process.env.PUBLIC_URL}/svg/gorom_logo_footer.svg`} alt="Logo" />
      </div>
    </footer>
  );
};

export { Footer };
