import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForums } from './ForumsContext';
import '../../../styles/graphicComponents/forums/AddForumEntry.css';

const AddForumEntry = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { user, isAuthenticated } = useAuth0();
  const { profiles, addForum } = useForums(); // Get all profiles and addForum method from ForumsContext

  const currentUserProfile = profiles.find(profile => profile.goromLogin === user.email);

  const handleSave = async () => {
    const creationDate = new Date().toISOString();
    const newForum = {
      title,
      content,
      author: isAuthenticated && currentUserProfile ? currentUserProfile.name : 'Anonymous',
      authorID: isAuthenticated && currentUserProfile ? currentUserProfile.id : null,
      creationDate, // Add the creation date to the forum object
    };
    await addForum(newForum); // Add the forum and update the state
    onClose(); // Close the modal or entry form
  };

  return (
    <div className="add-forum-entry">
      <button className="close-button" onClick={onClose}>X</button>
      <h2>Add New Forum Entry</h2>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export { AddForumEntry };
