import React, { useState, useEffect } from 'react';
import '../../../styles/graphicComponents/forums/CommentSection.css';
import { useForums } from './ForumsContext';
import { FaPencilAlt } from 'react-icons/fa'; // Import the pencil icon
import { useComments } from './CommentsContext';

const CommentSection = ({ comments, addComment, currentUser, isLoading }) => {
  const [comment, setComment] = useState('');
  const { profiles } = useForums();
  const { deleteComment } = useComments(); // Import deleteComment from CommentsContext
  const [isMatching, setIsMatching] = useState(true);
  const [matchedAuthors, setMatchedAuthors] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(null); // State to handle delete confirmation

  useEffect(() => {
    const matchAuthors = () => {
      const authorData = {};
      comments.forEach((comment) => {
        const profile = profiles.find(profile => profile.id === comment.authorID);
        if (profile) {
          authorData[comment.authorID] = profile;
        }
      });
      setMatchedAuthors(authorData);
      setIsMatching(false); // Set matching to false after all profiles are matched
    };

    if (!isLoading && comments.length > 0) {
      matchAuthors();
    } else {
      setIsMatching(false); // If no comments or already loaded, no need to match authors
    }
  }, [comments, profiles, isLoading]);

  const handleAddComment = () => {
    if (!comment.trim()) return;

    const creationDate = new Date().toISOString(); // Get the current date and time in ISO format
    const newComment = {
      text: comment,
      authorID: currentUser.id, // Store the author's ID
      creationDate: creationDate, // Store the current date
      forumId: currentUser.forumId,
    };
    console.log('New Comment:', newComment); // Debugging: Check the comment object
    addComment(newComment);
    setComment('');
  };

  const handleDeleteClick = (id) => {
    setConfirmDelete(id); // Show delete confirmation for the clicked comment
  };

  const confirmDeleteComment = async (id) => {
    await deleteComment(id);
    setConfirmDelete(null); // Reset delete confirmation state after deletion
  };

  // Sort comments by creationDate (most recent first)
  const sortedComments = comments.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

  return (
    <div className="comment-section">
      <h2 className='comments-header'>Comments</h2>
      <div className="comments-list">
        {isMatching ? (
          <div className="fun-loading-animation">
            <div className="ball"></div>
            <p>Loading... Please wait!</p>
          </div> // Show loading component
        ) : (
          sortedComments.map((comment, index) => {
            const author = matchedAuthors[comment.authorID];
            return (
              <div key={index} className="comment-bubble">
                <div className="comment-author">
                  {author && (
                    <>
                      <img src={author.picture} alt={author.name} className="comment-author-picture" />
                      <span>{author.name}</span>
                    </>
                  )}
                </div>
                <div>
                  <p id="creation-date-comment">{new Date(comment.creationDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>
                </div>
                <p className='comment-text'>{comment.content}</p>

                {comment.authorID === currentUser.id && ( // Show pencil icon if the current user is the author
                  <div id="edit-delete-container">
                    <p id="edit-comment-text">Delete your Comment</p>
                    <FaPencilAlt id="pencil-icon" onClick={() => handleDeleteClick(comment.id)} />
                    {confirmDelete === comment.id && (
                      <div id="confirm-delete">
                        <p>Are you sure you want to delete this comment?</p>
                        <button onClick={() => confirmDeleteComment(comment.id)}>Yes</button>
                        <button onClick={() => setConfirmDelete(null)}>No</button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
      <div className="add-comment">
        <textarea 
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment"
        />
        <button onClick={handleAddComment} className='add-comment-button'>Add Comment</button>
      </div>
    </div>
  );
};

export { CommentSection };
