import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CommentSection } from './CommentSection';
import { IoArrowBack } from 'react-icons/io5';
import { FaPencilAlt } from 'react-icons/fa'; // Import the pencil icon
import '../../../styles/graphicComponents/forums/ForumEntry.css';
import { useForums } from './ForumsContext';
import { useComments } from './CommentsContext';

const ForumEntry = ({ currentUser }) => {
  const { id: forumId } = useParams(); // Get forum ID from the URL (UUID string)
  const navigate = useNavigate();
  const { forums, deleteForum } = useForums(); // Access deleteForum from context
  const { comments, isLoading, addComment } = useComments();
  const [showLoading, setShowLoading] = useState(true); // State to handle loading animation
  const [confirmDelete, setConfirmDelete] = useState(null); // State to handle delete confirmation

  // Find the forum based on the UUID string from the URL
  const forum = forums.find(f => f.id === forumId);

  // Timer to display loading animation for 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  // Filter comments based on the current forum ID
  const filteredComments = comments.filter(comment => comment.forumId === forumId);

  const handleAddComment = (newComment) => {
    const commentDto = {
      authorID: currentUser.id, // Assuming currentUser has an id field
      content: newComment.text,
      creationDate: newComment.creationDate,
      forumId: forumId, // Associate comment with the current forum ID
    };
    addComment(commentDto);
  };

  const handleGoBack = () => {
    navigate('/forums');
  };

  const handleDeleteClick = (id) => {
    setConfirmDelete(id);
  };

  const confirmDeleteForum = async (id) => {
    await deleteForum(id);
    navigate('/forums'); // Navigate back to the forums list after deletion
  };

  if (!forum && showLoading) {
    return (
      <div className="fun-loading-animation">
        <div className="ball"></div>
        <p>Loading... Please wait!</p>
      </div>
    ); // Show loading component while waiting
  }

  if (!forum && !showLoading) {
    return <div>Forum entry not found</div>;
  }

  return (
    <div className="forum-entry-container">
      <button className="go-back-button" onClick={handleGoBack}>
        <IoArrowBack size={34} />
      </button>
      <h1 className='forum-title'>{forum.title}</h1>
      <div className="forum-author">By {forum.author}</div>
      <div className="forum-content">
        <p id="forum-text-content">{forum.content}</p>
        {forum.images && forum.images.map((img, idx) => (
          <img key={idx} src={img} alt={`Forum image ${idx + 1}`} />
        ))}
        <div id="creation-date">Creation Date: {new Date(forum.creationDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
        
        {forum.author === currentUser.name && ( // Show pencil icon if the current user is the author
          <div className="edit-delete-container">
            <FaPencilAlt id="pencil-icon" onClick={() => handleDeleteClick(forum.id)} />
            <p id="edit-forum-text">Delete your Post</p>
            {confirmDelete === forum.id && (
              <div className="confirm-delete">
                <p>Are you sure you want to delete this post?</p>
                <button onClick={() => confirmDeleteForum(forum.id)}>Yes</button>
                <button onClick={() => setConfirmDelete(null)}>No</button>
              </div>
            )}
          </div>
        )}
      </div>
      <CommentSection 
        comments={filteredComments} 
        addComment={handleAddComment} 
        currentUser={currentUser} 
        isLoading={isLoading} // Pass the loading state for handling loading UI
      />
    </div>
  );
};

export { ForumEntry };
