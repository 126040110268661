import React, { useState, useEffect } from 'react';
import '../../../styles/graphicComponents/recommendations/EditingNote.css';
import { useRecommendations } from './RecommendationsContext';

function EditingNote({ note, onClose }) {
    const { updateRecommendation } = useRecommendations();
    const [title, setTitle] = useState(note.title);
    const [description, setDescription] = useState(note.description);
    const [color, setColor] = useState(note.color);

    const handleUpdateNote = async () => {
        const updatedNote = {
            ...note,
            title,
            description,
            color,
        };
        await updateRecommendation(note.id, updatedNote);
        onClose();
    };

    useEffect(() => {
        // Scroll to the top of the page and lock scroll when the editing note is open
        window.scrollTo({ top: 100, behavior: 'smooth' });
        document.body.style.overflow = 'hidden';

        return () => {
            // Re-enable scroll when the component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div id="blurred-background"></div>
            <div id="editing-note-container">
                <button id="close-button" onClick={onClose}>X</button>
                <input 
                    type="text" 
                    placeholder="Title" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                />
                <textarea 
                    placeholder="Edit your note here..." 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                />
                <input 
                    type="color" 
                    value={color} 
                    onChange={(e) => setColor(e.target.value)}
                    id='color-picker'
                />
                <button onClick={handleUpdateNote}>Update Note</button>
            </div>
        </>
    );
}

export { EditingNote };
