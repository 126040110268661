import React, { createContext, useState, useContext, useEffect } from 'react';

const EventContext = createContext();

export const useEvents = () => useContext(EventContext);

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState({});

  // Backend URL
  //const baseURL = 'https://seed-alumni.org/api/v1'

  const baseURL = 'https://seed-alumni.org/api/v1'; // Development URL

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${baseURL}/events`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const eventsByDate = data.reduce((acc, event) => {
        const date = event.date.split('T')[0];  // Assuming the date comes in ISO format
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(event);
        return acc;
      }, {});
      setEvents(eventsByDate);
    } catch (error) {
      console.error("Failed to fetch events:", error);
      return [];
    }
  }; 
  
  const addEvent = async (eventDto) => {
    console.log('Sending event:', eventDto);  // Log the DTO being sent
    const response = await fetch(`${baseURL}/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventDto),
    });
    if (response.ok) {  // Check if the POST request was successful
      fetchEvents();  // Refetch all events
    }
    return response;
  };

  const updateEvent = async (id, eventDto) => {
    const response = await fetch(`${baseURL}/events/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(eventDto),
    });
    if (response.ok) {  // Check if the PUT request was successful
      fetchEvents();  // Refetch all events to update the state
    }
    return response;
  };

  const deleteEvent = async (id) => {
    const response = await fetch(`${baseURL}/events/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {  // Check if the DELETE request was successful
      fetchEvents();  // Refetch all events to remove the deleted event from state
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <EventContext.Provider value={{ events, addEvent, updateEvent, deleteEvent, fetchEvents }}>
      {children}
    </EventContext.Provider>
  );
};
