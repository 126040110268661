import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../header/Header';
import { Footer } from '../../footer/Footer';
import '../../../../styles/graphicComponents/clubs/concrete-clubs/Research.css';
import { ScheduleEvent } from '../ScheduleEvent';
import { EventsProvider } from '../../calendar/EventContext';
import { FunLoading } from '../../Loading/FunLoading';
import { useAuth0 } from '@auth0/auth0-react';
import { EditingClub } from './EditingClub';

function Research() {
    const [tasks, setTasks] = useState({});
    const [showScheduleEvent, setShowScheduleEvent] = useState(false);
    const [clubData, setClubData] = useState(null);
    const { user, isAuthenticated } = useAuth0(); // Use Auth0 to get user details
    const [isEditingClub, setIsEditingClub] = useState(false);

    //const baseURL = 'https://seed-alumni.org/api/v1';

    const baseURL = 'https://seed-alumni.org/api/v1'; // Development

    const fetchClubs = async () => {
        try {
            const response = await fetch(`${baseURL}/clubs`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const researchClub = data.find(club => club.name === 'Quantitative Research Methods');
            setClubData(researchClub);
        } catch (error) {
            console.error("Failed to fetch clubs:", error);
        }
    };

    useEffect(() => {
        fetchClubs();
    }, []);

    const toggleScheduleEvent = () => {
        setShowScheduleEvent(!showScheduleEvent);
    };

    const addEvent = (date, description) => {
        const newTasks = { ...tasks };
        if (!newTasks[date]) newTasks[date] = [];
        newTasks[date].push(description);
        setTasks(newTasks);
    };

    const navigate = useNavigate();

    const handleNavigate = (path) => {
        if (path === '/syllabus/Research') {
            window.open('https://docs.google.com/document/d/1kVgwpzlI8rVSneoCe9hihWjOCttLFxjJadZVLs7Nipk/edit', '_blank');
        } else {
            navigate(path);
        }
    };

    // Role check for Admin
    const hasRole = (role) => {
        return user && user['https://my-intranet-app/roles'] && user['https://my-intranet-app/roles'].includes(role);
    };

    const isAdmin = isAuthenticated && hasRole('Admin');

    const toggleEditingClub = () => {
        setIsEditingClub(!isEditingClub);
    };

    const saveClubChanges = async (updatedClub) => {
        const { id, name, bannerImage, mainDescription } = updatedClub;
        
        const clubDto = {
            name,
            bannerImage,
            mainDescription,
        };
    
        try {
            const response = await fetch(`${baseURL}/clubs/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(clubDto),
            });
    
            if (response.ok) {
                const updatedData = await response.json();
                setClubData(updatedData); // Update the local state with the latest data
                toggleEditingClub(); // Close the editing form
            } else {
                alert('Failed to update club, please contact Fai');
                console.error('Failed to update club:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating club:', error);
        }
    };

    if (!clubData) {
        return <div><FunLoading/></div>;
    }

    return (
        <div className="concrete-club-container">
            <Header />
            <div className="club-7-container">
                <div className="club-content">
                    <div className="main-content">
                        <h1 className='club-7-header'>{clubData.name} Club</h1>
                        <img src={clubData.bannerImage} alt={`${clubData.name} Club Banner`} className="club-banner"/>
                        <p className="club-description">
                            {clubData.mainDescription}
                        </p>
                    </div>
                    <div className="sidebar">
                        <h2 className="sidebar-header">Club Contents</h2>
                        <button className="sidebar-7-button" onClick={() => handleNavigate(`/syllabus/Research`)}>Syllabus</button>
                        <button className="sidebar-7-button" onClick={() => handleNavigate(`/documents/Research`)}>Documents</button>
                        <button className="sidebar-7-button" onClick={() => handleNavigate(`/meetings/Research`)}>Meetings</button>
                        
                    
                        {/* Admin-only button */}
                        {isAdmin && (
                            <div>
                                <h2 className='sidebar-header'>Alumni Functions</h2>
                                <button className="admin-edit-club-button" onClick={() => setShowScheduleEvent(!showScheduleEvent)}>Schedule an event</button>
                                <button className="admin-edit-club-button" onClick={toggleEditingClub}>
                                    Edit My Club
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showScheduleEvent && (
                <EventsProvider>
                    <ScheduleEvent onClose={toggleScheduleEvent} addEvent={addEvent} currentClub={"Research"} />
                </EventsProvider>
            )}

            {isEditingClub && (
                <EditingClub 
                    club={clubData} 
                    onSave={saveClubChanges} 
                    onClose={toggleEditingClub} 
                />
            )}

            <Footer />
        </div>
    );
}

export { Research };
