import React, { createContext, useContext } from 'react';
import { useAppData } from '../mainMenu/AppDataProvider';

const MeetingsContext = createContext();

export const useMeetings = () => useContext(MeetingsContext);

export const MeetingsProvider = ({ children }) => {
  const { appData, setAppData } = useAppData(); // Access AppData and setAppData from AppDataProvider
  const baseURL = 'https://seed-alumni.org/api/v1/meetings';

  // Fetch all meetings and update AppData
  const fetchMeetings = async () => {
    try {
      const response = await fetch(baseURL); // Fetch all meetings
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Update meetings in AppData (store all meetings)
      setAppData(prevData => ({
        ...prevData,
        meetings: data,
      }));
    } catch (error) {
      console.error('Failed to fetch meetings:', error);
    }
  };

  // Add a new meeting and update AppData
  const addMeeting = async (meetingDto) => {
    try {
      const response = await fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(meetingDto),
      });
      if (response.ok) {
        // Re-fetch meetings after adding
        await fetchMeetings();
      }
      return response;
    } catch (error) {
      console.error('Failed to add meeting:', error);
    }
  };

  // Update a meeting and update AppData
  const updateMeeting = async (id, meetingDto) => {
    try {
      const response = await fetch(`${baseURL}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(meetingDto),
      });
      if (response.ok) {
        // Re-fetch meetings after updating
        await fetchMeetings();
      }
      return response;
    } catch (error) {
      console.error('Failed to update meeting:', error);
    }
  };

  // Delete a meeting and update AppData
  const deleteMeeting = async (id) => {
    try {
      const response = await fetch(`${baseURL}/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Re-fetch meetings after deletion
        await fetchMeetings();
      }
    } catch (error) {
      console.error('Failed to delete meeting:', error);
    }
  };

  return (
    <MeetingsContext.Provider value={{
      meetings: appData.meetings || [], // Use meetings from AppData
      fetchMeetings,
      addMeeting,
      updateMeeting,
      deleteMeeting,
    }}>
      {children}
    </MeetingsContext.Provider>
  );
};
